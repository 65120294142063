.footer-page-footer-page {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
}
.footer-page-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-page-container {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.footer-page-navlink {
  display: contents;
}
.footer-page-image {
  height: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-page-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.footer-page-navlink1 {
  margin-left: 0px;
  text-decoration: none;
}
.footer-page-navlink2 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-page-navlink3 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-page-navlink4 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-page-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-page-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-page-text {
  text-align: center;
}
.footer-page-text2 {
  color: var(--dl-color-primary-bluelb3);
}
.footer-page-text5 {
  color: var(--dl-color-primary-redlb3);
}
.footer-page-text7 {
  color: var(--dl-color-primary-orangelb3);
}









@media(max-width: 767px) {
  .footer-page-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-page-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-page-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-page-text {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .footer-page-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-page-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-page-text {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
