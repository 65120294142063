.people-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/backgroundlow-1500w.webp");
}
.people-container1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-image: linear-gradient(135deg, rgb(0, 69, 255) 72.00%,rgb(251, 17, 17) 57.00%,rgb(251, 17, 17) 83.00%,rgb(244, 131, 0) 45.00%);
}
.people-text {
  color: var(--dl-color-primary-0);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.people-container2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-image: linear-gradient(135deg, rgba(0, 68, 255) 38.00%,rgb(251, 17, 17) 19.00%,rgb(251, 17, 17) 83.00%,rgb(244, 131, 0) 45.00%);
}
.people-text1 {
  color: var(--dl-color-primary-0);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.people-container3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-image: linear-gradient(135deg, rgb(0, 69, 255) 8.00%,rgb(251, 17, 17) 1.00%,rgb(251, 17, 17) 56.00%,rgb(244, 131, 0) 40.00%);
}
.people-text2 {
  color: var(--dl-color-primary-0);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.people-container4 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-image: linear-gradient(135deg, rgb(0, 69, 255) 10.00%,rgb(251, 17, 17) 0.00%,rgb(251, 17, 17) 29.00%,rgb(244, 131, 0) 0.00%);
}
.people-text3 {
  color: var(--dl-color-primary-0);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.people-container5 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  background-image: linear-gradient(135deg, rgb(0, 69, 255) 5.00%,rgb(251, 17, 17) 0.00%,rgb(251, 17, 17) 11.00%,rgb(244, 131, 0) 0.00%);
}
.people-text4 {
  color: var(--dl-color-primary-0);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
@media(max-width: 1600px) {
  .people-container1 {
    width: 100%;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .people-container2 {
    width: 100%;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .people-container3 {
    width: 100%;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .people-container4 {
    width: 100%;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .people-container5 {
    width: 100%;
    background-color: var(--dl-color-primary-bluelb3);
  }
}
