.head-title-banner {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.head-title-text {
  color: var(--dl-color-primary-0);
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 36.00%,rgb(214, 40, 40) 21.00%,rgb(214, 40, 40) 73.00%,rgb(247, 127, 0) 65.00%);
}



@media(max-width: 1600px) {
  .head-title-text {
    font-size: 3rem;
  }
}
@media(max-width: 767px) {
  .head-title-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .head-title-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
