.page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/background-1500w.webp");
}
.page-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.page-hero {
  width: 1392px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-container2 {
  width: 895px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.page-text {
  font-size: 16px;
}
.page-text01 {
  font-size: 30px;
}
.page-text02 {
  font-size: 30px;
}
.page-text05 {
  color: var(--dl-color-primary-0);
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  background-color: var(--dl-color-primary-bluelb3);
}
.page-text06 {
  color: var(--dl-color-primary-0);
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  background-color: var(--dl-color-primary-redlb3);
}
.page-text07 {
  color: var(--dl-color-primary-0);
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  background-color: var(--dl-color-primary-orangelb3);
}
.page-text08 {
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
}
.page-image {
  width: 518px;
  height: 451px;
  margin: var(--dl-space-space-twounits);
  align-self: center;
  object-fit: cover;
}
@media(max-width: 1600px) {
  .page-container2 {
    width: 1211px;
  }
  .page-text {
    font-size: 16px;
  }
  .page-text02 {
    font-size: 100px;
  }
  .page-text03 {
    font-size: 100px;
  }
  .page-text05 {
    color: var(--dl-color-primary-0);
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .page-text06 {
    color: var(--dl-color-primary-0);
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-redlb3);
  }
  .page-text07 {
    color: var(--dl-color-primary-0);
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-orangelb3);
  }
  .page-text08 {
    font-style: normal;
    font-weight: 800;
  }
}
@media(max-width: 1200px) {
  .page-hero {
    width: 100%;
  }
  .page-container2 {
    width: 1105px;
  }
  .page-text {
    font-size: 16px;
  }
  .page-text01 {
    font-size: 30px;
  }
  .page-text02 {
    font-size: 50px;
  }
  .page-text03 {
    font-size: 50px;
  }
  .page-text05 {
    color: var(--dl-color-primary-0);
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .page-text06 {
    color: var(--dl-color-primary-0);
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-redlb3);
  }
  .page-text07 {
    color: var(--dl-color-primary-0);
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-orangelb3);
  }
  .page-text08 {
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
  }
}
@media(max-width: 991px) {
  .page-hero {
    width: 991px;
    flex-direction: column;
  }
  .page-container2 {
    width: 849px;
    height: 299px;
  }
  .page-text {
    font-size: 16px;
    align-self: center;
  }
  .page-text01 {
    font-size: 30px;
  }
  .page-text02 {
    font-size: 50px;
  }
  .page-text03 {
    font-size: 50px;
  }
  .page-text05 {
    color: var(--dl-color-primary-0);
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .page-text06 {
    color: var(--dl-color-primary-0);
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-redlb3);
  }
  .page-text07 {
    color: var(--dl-color-primary-0);
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-orangelb3);
  }
  .page-text08 {
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
  }
  .page-image {
    width: 433px;
    height: 386px;
  }
}
@media(max-width: 767px) {
  .page-hero {
    width: 767px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .page-container2 {
    width: 721px;
    height: 310px;
  }
}
@media(max-width: 479px) {
  .page-hero {
    width: 477px;
    height: 889px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .page-container2 {
    width: 455px;
    height: 334px;
  }
  .page-text {
    font-size: 16px;
  }
  .page-text01 {
    font-size: 30px;
  }
  .page-text02 {
    font-size: 50px;
  }
  .page-text03 {
    font-size: 50px;
  }
  .page-text05 {
    color: var(--dl-color-primary-0);
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-bluelb3);
  }
  .page-text06 {
    color: var(--dl-color-primary-0);
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-redlb3);
  }
  .page-text07 {
    color: var(--dl-color-primary-0);
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    background-color: var(--dl-color-primary-orangelb3);
  }
  .page-text08 {
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
  }
  .page-image {
    width: 315px;
    height: 267px;
  }
}
