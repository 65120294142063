.research-card-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.research-card-image {
  width: 500px;
  object-fit: cover;
}
.research-card-container {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.research-card-text {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.research-card-text1 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.research-card-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.research-card-text2 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.research-card-text3 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
}




@media(max-width: 991px) {
  .research-card-image {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .research-card-blog-post-card {
    flex-direction: column;
    justify-content: space-between;
  }
  .research-card-image {
    width: 100%;
  }
  .research-card-container {
    width: 100%;
  }
  .research-card-text {
    margin-bottom: var(--dl-space-space-unit);
  }
  .research-card-text1 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .research-card-container1 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
