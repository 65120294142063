.homepage-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/background-1500w.webp");
}
.homepage-container01 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.homepage-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.homepage-container02 {
  width: 895px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.homepage-container03 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.homepage-text {
  font-size: 6rem;
  max-width: 450px;
}
.homepage-text01 {
  color: var(--dl-color-primary-bluelb3);
}
.homepage-text03 {
  color: var(--dl-color-primary-redlb3);
}
.homepage-text05 {
  color: var(--dl-color-primary-orangelb3);
}
.homepage-text07 {
  font-size: 20px;
}
.homepage-image {
  width: 850px;
  height: 358px;
  object-fit: cover;
}
.homepage-image1 {
  width: 783px;
  height: 360px;
  display: none;
  object-fit: cover;
}
.homepage-lottie-node {
  width: 300px;
  height: 300px;
  display: none;
}
.homepage-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.homepage-text08 {
  color: white;
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.homepage-text09 {
  color: var(--dl-color-primary-bluelb3);
}
.homepage-text10 {
  color: var(--dl-color-primary-redlb3);
}
.homepage-text12 {
  color: var(--dl-color-primary-orangelb3);
}
.homepage-separator {
  width: 100px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}
.homepage-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.homepage-container05 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.homepage-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.homepage-icon {
  width: 24px;
  height: 24px;
}
.homepage-text13 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.homepage-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.homepage-icon2 {
  width: 24px;
  height: 24px;
}
.homepage-text14 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.homepage-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.homepage-icon4 {
  width: 24px;
  height: 24px;
}
.homepage-text15 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.homepage-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.homepage-icon6 {
  width: 24px;
  height: 24px;
}
.homepage-text16 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.homepage-image2 {
  width: 450px;
  height: 450px;
  object-fit: cover;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-fourunits);
  object-position: left;
}
.homepage-container06 {
  display: flex;
  flex-direction: column;
}
.homepage-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homepage-navlink {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.homepage-navlink:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-text18:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.homepage-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  vertical-align: middle;
  justify-content: center;
}
.homepage-image3 {
  width: 396px;
  height: 291px;
  padding: 30;
  object-fit: cover;
}
.homepage-container09 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.homepage-text20 {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
}
.homepage-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.homepage-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.homepage-container12 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.homepage-text28 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.homepage-container13 {
  display: flex;
  flex-direction: column;
}
.homepage-btn-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homepage-navlink1 {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.homepage-navlink1:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-text35 {
  color: rgb(255, 255, 255);
}
.homepage-text36:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-banner {
  width: 100%;
  height: 907px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.homepage-text38 {
  color: white;
  font-size: 3rem;
  text-align: center;
}
.homepage-text39 {
  color: var(--dl-color-primary-bluelb3);
}
.homepage-text40 {
  color: var(--dl-color-primary-redlb3);
}
.homepage-text42 {
  color: var(--dl-color-primary-orangelb3);
}
.homepage-image4 {
  width: 1334px;
  height: 597px;
  object-fit: cover;
}
.homepage-container14 {
  display: flex;
  flex-direction: column;
}
.homepage-btn-group2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.homepage-navlink2 {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.homepage-navlink2:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-text45:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-hero2 {
  width: 100%;
  height: 910px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.homepage-container15 {
  display: flex;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.homepage-text47 {
  color: var(--dl-color-primary-bluelb3);
  font-size: 3rem;
  max-width: 450px;
}
.homepage-text49 {
  color: var(--dl-color-primary-redlb3);
}
.homepage-text50 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}
.homepage-image5 {
  width: 200px;
  object-fit: cover;
}
.homepage-btn-group3 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.homepage-navlink3 {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.homepage-navlink3:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.homepage-image6 {
  width: 566px;
  height: 479px;
  object-fit: cover;
}
@media(max-width: 1600px) {
  .homepage-features {
    height: 814px;
  }
  .homepage-text08 {
    font-size: 3rem;
  }
  .homepage-hero1 {
    background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
  }
  .homepage-text20 {
    color: var(--dl-color-gray-white);
    font-size: 3rem;
  }
  .homepage-banner {
    height: 833px;
  }
  .homepage-text38 {
    font-size: 3rem;
  }
  .homepage-image4 {
    width: 1130px;
    height: 486px;
  }
}
@media(max-width: 1200px) {
  .homepage-container02 {
    width: 50%;
  }
  .homepage-text {
    font-size: 4rem;
  }
  .homepage-text07 {
    font-size: 16;
  }
  .homepage-image {
    width: 587px;
    height: 250px;
  }
  .homepage-image1 {
    width: 682px;
    height: 298px;
  }
  .homepage-features {
    height: 824px;
  }
}
@media(max-width: 991px) {
  .homepage-hero {
    flex-direction: column;
  }
  .homepage-container02 {
    width: 731px;
    height: 404px;
  }
  .homepage-container03 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .homepage-text {
    text-align: center;
  }
  .homepage-text01 {
    text-align: center;
  }
  .homepage-image {
    width: 786px;
    height: 321px;
  }
  .homepage-features {
    height: 991px;
    align-items: center;
  }
  .homepage-container04 {
    flex-direction: column;
  }
  .homepage-image2 {
    width: 300px;
    height: 300px;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }
  .homepage-hero1 {
    flex-direction: column;
  }
  .homepage-text20 {
    text-align: center;
  }
  .homepage-text21 {
    text-align: center;
  }
  .homepage-text28 {
    text-align: center;
  }
  .homepage-banner {
    height: 713px;
  }
  .homepage-image4 {
    width: 743px;
    height: 336px;
  }
  .homepage-hero2 {
    flex-direction: column;
  }
  .homepage-container15 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .homepage-text47 {
    text-align: center;
  }
  .homepage-text50 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .homepage-image6 {
    width: 444px;
    height: 419px;
  }
}
@media(max-width: 767px) {
  .homepage-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-container02 {
    width: 572px;
    height: 416px;
  }
  .homepage-container03 {
    width: 489px;
  }
  .homepage-image {
    width: 656px;
    height: 290px;
  }
  .homepage-image1 {
    width: 80%;
    height: 210px;
  }
  .homepage-features {
    height: 1011px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-text08 {
    text-align: center;
  }
  .homepage-container04 {
    flex-direction: column;
  }
  .homepage-image2 {
    width: 328px;
  }
  .homepage-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-image3 {
    width: 314px;
    height: 219px;
  }
  .homepage-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-image4 {
    width: 567px;
    height: 247px;
  }
  .homepage-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-text50 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .homepage-image6 {
    width: 80%;
  }
}
@media(max-width: 479px) {
  .homepage-hero {
    height: 471px;
    min-height: 65vh;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container02 {
    width: 366px;
    height: 482px;
  }
  .homepage-container03 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }
  .homepage-text {
    width: 320px;
  }
  .homepage-text07 {
    font-size: 16px;
  }
  .homepage-image {
    width: 436px;
    height: 186px;
  }
  .homepage-image1 {
    width: 373px;
    height: 169px;
  }
  .homepage-features {
    height: 1111px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container05 {
    grid-template-columns: 1fr;
  }
  .homepage-text13 {
    font-style: normal;
    font-weight: 600;
  }
  .homepage-text14 {
    font-style: normal;
    font-weight: 600;
  }
  .homepage-text15 {
    font-style: normal;
    font-weight: 600;
  }
  .homepage-text16 {
    font-style: normal;
    font-weight: 600;
  }
  .homepage-image2 {
    width: 226px;
    height: 241px;
  }
  .homepage-btn-group {
    flex-direction: column;
  }
  .homepage-hero1 {
    width: 100%;
    height: 773px;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .homepage-container08 {
    flex-direction: column;
  }
  .homepage-btn-group1 {
    flex-direction: column;
  }
  .homepage-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-image4 {
    width: 425px;
    height: 166px;
  }
  .homepage-btn-group2 {
    flex-direction: column;
  }
  .homepage-hero2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container15 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .homepage-btn-group3 {
    flex-direction: column;
  }
  .homepage-image6 {
    width: 348px;
    height: 296px;
  }
}
