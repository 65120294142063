.register-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/backgroundlow-1500w.webp");
}
.register-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.register-text {
  color: var(--dl-color-primary-0);
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26%, rgb(247, 127, 0) 77%, rgb(214, 40, 40) 46%);
}
.register-banner1 {
  width: 100%;
  height: 338px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.register-navlink {
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.register-navlink:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.register-text2:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
@media(max-width: 1600px) {
  .register-text {
    font-size: 3rem;
    background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
  }
  .register-banner1 {
    height: 383px;
    justify-content: center;
  }
  .register-navlink {
    margin: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .register-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .register-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .register-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .register-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
