.resources-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/backgroundlow-1500w.webp");
}
.resources-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.resources-hero {
  width: 1218px;
  height: 699px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.resources-container2 {
  width: 1258px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.resources-text {
  font-size: 30px;
  align-self: center;
}
.resources-text02 {
  font-style: normal;
  font-weight: 800;
}
.resources-image {
  width: 334px;
  align-self: center;
  object-fit: cover;
}
.resources-text05 {
  font-size: 20px;
}
.resources-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resources-image1 {
  width: 200px;
  object-fit: cover;
}
.resources-link {
  margin: var(--dl-space-space-twounits);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.resources-link:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
.resources-text14:hover {
  color: var(--dl-color-gray-white);
  background-image: radial-gradient(circle at left top, rgb(0, 100, 151) 26.00%,rgb(247, 127, 0) 77.00%,rgb(214, 40, 40) 46.00%);
}
@media(max-width: 1600px) {
  .resources-hero {
    height: 756px;
  }
}
@media(max-width: 1200px) {
  .resources-container2 {
    width: 50%;
  }
  .resources-text05 {
    font-size: 16;
  }
}
@media(max-width: 991px) {
  .resources-hero {
    flex-direction: column;
  }
  .resources-container2 {
    width: 731px;
    height: 404px;
  }
}
@media(max-width: 767px) {
  .resources-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .resources-container2 {
    width: 572px;
    height: 416px;
  }
}
@media(max-width: 479px) {
  .resources-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .resources-container2 {
    width: 285px;
    height: 421px;
  }
}
