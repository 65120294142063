.header-page-header {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.header-page-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.header-page-nav {
  display: flex;
}
.header-page-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-page-navlink {
  text-decoration: none;
}
.header-page-navlink1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-page-navlink2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-page-navlink3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-page-navlink4 {
  display: contents;
}
.header-page-image {
  width: 70px;
  object-fit: cover;
  text-decoration: none;
}
.header-page-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-page-login {
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-page-register {
  text-decoration: none;
}
.header-page-burger-menu {
  display: none;
}
.header-page-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-page-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-page-nav2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-page-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-page-image1 {
  height: 2rem;
}
.header-page-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-page-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-page-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-page-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-page-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}










@media(max-width: 767px) {
  .header-page-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-page-nav {
    display: none;
  }
  .header-page-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .header-page-navlink {
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-page-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-page-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-page-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .header-page-btn-group {
    display: none;
  }
  .header-page-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .header-page-header {
    padding: var(--dl-space-space-unit);
  }
  .header-page-mobile-menu {
    padding: 16px;
  }
}
