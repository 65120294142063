.blank-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.blank-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.blank-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blank-container2 {
  width: 895px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.blank-container3 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: center;
}
.blank-text {
  font-size: 20px;
}
@media(max-width: 1200px) {
  .blank-container2 {
    width: 50%;
  }
  .blank-text {
    font-size: 16;
  }
}
@media(max-width: 991px) {
  .blank-hero {
    flex-direction: column;
  }
  .blank-container2 {
    width: 731px;
    height: 404px;
  }
  .blank-container3 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
}
@media(max-width: 767px) {
  .blank-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .blank-container2 {
    width: 572px;
    height: 416px;
  }
  .blank-container3 {
    width: 489px;
  }
}
@media(max-width: 479px) {
  .blank-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .blank-container2 {
    width: 285px;
    height: 421px;
  }
  .blank-container3 {
    width: 100%;
    margin-bottom: var(--dl-space-space-unit);
  }
}
