.people-card-testimonial-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: space-between;
}
.people-card-image {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xlarge);
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-round);
}
.people-card-testimonial {
  display: flex;
  align-items: flex-end;
  margin-left: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: space-between;
}
.people-card-text {
  color: var(--dl-color-gray-500);
  font-size: 1.15rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.people-card-text1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.people-card-text2 {
  font-size: 1.5rem;
  font-weight: 600;
}
.people-card-textcv {
  font-size: 0.9rem;
  font-weight: 600;
  color:dimgrey
}
.people-card-root-class-name {
  align-self: center;
}
.people-card-root-class-name1 {
  align-self: center;
}
.people-card-root-class-name2 {
  align-self: flex-start;
}
.people-card-root-class-name3 {
  align-self: center;
}
.people-card-root-class-name4 {
  align-self: center;
}
.people-card-root-class-name5 {
  align-self: center;
}
.people-card-root-class-name6 {
  align-self: center;
}
.people-card-root-class-name7 {
  align-self: center;
}
@media(max-width: 1600px) {
  .people-card-root-class-name {
    align-self: center;
  }
  .people-card-root-class-name1 {
    align-self: center;
  }
  .people-card-root-class-name3 {
    align-self: center;
  }
  .people-card-root-class-name4 {
    align-self: center;
  }
  .people-card-root-class-name5 {
    align-self: center;
  }
  .people-card-root-class-name6 {
    align-self: center;
  }
  .people-card-root-class-name7 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .people-card-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .people-card-testimonial {
    margin-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .people-card-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .people-card-testimonial {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .people-card-text {
    text-align: left;
  }
}
