.blog-post-card21-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: stretch;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card21-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-post-card21-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 0px;
}
.blog-post-card21-container {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.blog-post-card21-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card21-text {
  color: var(--dl-color-gray-500);
  font-weight: 600;
  text-transform: uppercase;
}
.blog-post-card21-text1 {
  color: var(--dl-color-gray-500);
  font-weight: 300;
}
.blog-post-card21-text2 {
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-post-card21-text3 {
  color: var(--dl-color-gray-500);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.blog-post-card21-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.blog-post-card21-profile {
  display: flex;
  align-items: center;
}
.blog-post-card21-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.blog-post-card21-text4 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.blog-post-card21-text5 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-weight: 500;
}



@media(max-width: 991px) {
  .blog-post-card21-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .blog-post-card21-blog-post-card {
    flex-direction: column;
  }
  .blog-post-card21-image {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .blog-post-card21-container2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-post-card21-text5 {
    width: auto;
  }
}
