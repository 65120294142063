.courses-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-image: url("/backgroundlow-1500w.webp");
}
.courses-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.courses-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.courses-container2 {
  width: 895px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.courses-text {
  font-size: 45px;
}
.courses-image {
  width: 200px;
  object-fit: cover;
}
.courses-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
@media(max-width: 1200px) {
  .courses-container2 {
    width: 539px;
  }
  .courses-text {
    font-size: 40px;
  }
}
@media(max-width: 991px) {
  .courses-hero {
    flex-direction: column;
  }
  .courses-container2 {
    width: 731px;
    height: 404px;
  }
  .courses-text {
    font-size: 35px;
  }
  .courses-image {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .courses-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .courses-container2 {
    width: 572px;
    height: 416px;
  }
  .courses-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .courses-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .courses-container2 {
    width: 285px;
    height: 421px;
  }
  .courses-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
